/* eslint-disable angular/document-service */
import { Settings } from './types/settings.type';
import { Target } from './types/target.type';

export class CwElementHandler {
  private window: any;

  public element: HTMLIFrameElement | undefined;

  private settings: Settings;

  private target: Target;

  public htmlContent?: string;

  private cssContent?: string;

  public booted: boolean = false;

  constructor(
    private _window: any,
    private _htmlContent: string,
    private _cssContent: string,
    private _target: Target,
    private _settings: Settings,
  ) {
    this.window = this._window;
    this.htmlContent = this._htmlContent;
    this.cssContent = this._cssContent;
    this.target = this._target;
    this.settings = this._settings;

    this.setStyles();
  }

  boot(userInformation?: any, widgetId?: string): void {
    if (this.element) {
      this.shutdown();
    }
    setTimeout(() => {
      if (widgetId) {
        this.settings.widgetId = widgetId;
      }
      this.settings.user = userInformation;

      this.element = document.createElement('iframe');
      this.element.id = this.settings.standalone
        ? 'onlim-chat-widget-lite'
        : 'onlim-chat-widget';
      this.element.className = 'onlim-' + this.settings.widgetName;
      this.element.title = 'Chatwidget';

      if (this.element.dataset) {
        this.element.dataset.targetSettings = this.target.settings;
        this.element.dataset.targetApi = this.target.api;
      } else {
        this.element.setAttribute('data-target-settings', this.target.settings);
        this.element.setAttribute('data-target-api', this.target.api);
      }

      this.element.setAttribute('allow', 'microphone; autoplay');
      this.element.setAttribute('allowfullscreen', '');

      this.element.style.setProperty('position', 'absolute');
      this.element.style.setProperty('opacity', '0');
      this.element.style.setProperty('width', '1px');
      this.element.style.setProperty('height', '1px');
      this.element.style.setProperty('top', '0');
      this.element.style.setProperty('left', '0');
      this.element.style.setProperty('border', 'none');
      this.element.style.setProperty('display', 'block');
      this.element.style.setProperty('z-index', '-1');

      if (this.settings.standalone) {
        this.window.document
          .getElementById('onlim-chat-widget-lite-anchor')
          .appendChild(this.element);
      } else {
        this.window.document.body.appendChild(this.element);
      }

      const baseUrl = this.getOriginByScriptSrc();
      if (!baseUrl) {
        console.error(
          "Script element for booting KG search widget couldn't be found",
        );
        return;
      }

      if (!this.htmlContent) {
        console.error('Html Content is missing');
        return;
      }

      this.htmlContent = this.htmlContent
        .replace(
          '<base href="/chat-app/app/"',
          '<base href="' + baseUrl + '/chat-app/app/"',
        )
        .replace(
          '<base href="/chat-app/app-v2/"',
          '<base href="' + baseUrl + '/chat-app/app-v2/"',
        );

      this.element.contentWindow.document.open();
      this.element.contentWindow.document.write(this.htmlContent);
      this.element.contentWindow.document.close();
    }, 0);
  }

  shutdown(): void {
    this.booted = false;
    const elementIds = [
      'onlim-chat-widget',
      'onlim-chat-widget-lite',
      'onlim-chat-widget-trigger',
      'onlim-chat-widget-unread',
      'onlim-chat-widget-preview',
      'onlim-chat-widget-gradient',
    ];
    for (let i = 0; i < elementIds.length; i++) {
      const elements = document.querySelectorAll(
        '#' + elementIds[i] + '.onlim-' + this.settings.widgetName,
      );
      for (let j = 0; j < elements.length; j++) {
        const element = elements[j];
        element.parentNode.removeChild(element);
      }
    }
    this.element = null;
  }

  setStyles(): void {
    const head =
      this.window.document.head ||
      this.window.document.getElementsByTagName('head')[0];
    const style: any = document.createElement('style');
    head.appendChild(style);
    style.type = 'text/css';
    if (style.styleSheet) {
      // This is required for IE8 and below. Do we need that?
      style.styleSheet.cssText = this.cssContent;
    } else {
      style.appendChild(document.createTextNode(this.cssContent));
    }
  }

  private getOriginByScriptSrc(): string | undefined {
    const scripts = document.getElementsByTagName('script');
    const scriptsList: HTMLScriptElement[] =
      Array.prototype.slice.call(scripts);

    let match: string | undefined;
    const regex = new RegExp(
      '(/chat-app(?:-lite)?/js/host|/chat-app/booter(?:-standalone)?).js$',
    );
    scriptsList.forEach((script) => {
      const { src } = script;
      if (regex.test(src)) {
        match = src;
      }
    });

    if (match) {
      const cwScriptUrl = document.createElement('a');
      cwScriptUrl.href = match;
      return cwScriptUrl.origin;
    }
    return undefined;
  }
}
